import React, { Component } from 'react';

const SocketContext = React.createContext();

class SocketProvider extends Component {
  // Context state
  // eslint-disable-next-line react/state-in-constructor
  state = {
    socket: null,
    keyData: null,
    key: null,
  };

  // Method to update state
  setKey = (key) => {
    // console.log('key set', key);
    this.setState(() => ({ key }));
  };

  // Method to update state
  setKeyData = (keyData) => {
    // console.log('socket set', socket);
    this.setState(() => ({ keyData }));
  };

  // Method to update state
  setSocket = (socket) => {
    // console.log('socket set', socket);
    this.setState(() => ({ socket }));
  };

  render() {
    // console.log('socket render');
    const { children } = this.props;
    const { socket, key, keyData } = this.state;

    const { setSocket, setKey, setKeyData } = this;
    return (
      <SocketContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          socket,
          setSocket,
          key,
          setKey,
          keyData,
          setKeyData,
        }}
      >
        {children}
      </SocketContext.Provider>
    );
  }
}

export default SocketContext;

export { SocketProvider };
