import React from 'react';
import {
  doc, getDoc,
} from 'firebase/firestore';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import withRouter from '../service/withRouter';
import Toast from '../components/Toast';
import { wsEndpoint } from '../config';
import { getWsInstance } from '../service/Websocket';
import { db } from '../service/firebase';
import { setActiveKey } from '../redux/slices/key';
import { setConnected } from '../redux/slices/bitmexWs';
import SocketContext from '../context/SocketContext';

class BitmexLayout extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static contextType = SocketContext;

  constructor(props) {
    super(props);
    // console.log('layout', props, this.props);
    this.state = {
      key: props.router.params.apiKey,
      error: null,
      breadcrumbs: props.breadcrumbs,
    };
    this.connect = this.connect.bind(this);
  }

  async componentDidMount() {
    const { key } = this.state;
    // console.log('key', key);
    if (key) {
      this.loadKey();
      this.connect(key);
    }
  }

  async loadKey() {
    const { key } = this.state;
    if (key) {
      const keyRef = doc(db, 'keys', key);

      const snapshot = await getDoc(keyRef);
      if (snapshot.exists()) {
        const keyData = snapshot.data();
        keyData.id = snapshot.id;
        delete keyData.updatedAt;
        this.props.dispatch(setActiveKey(keyData));
        this.context.setKeyData(keyData);
      } else {
        this.setState({ error: 'Chave API inválida' });
      }
    }
  }

  connect(key) {
    // console.log('connect', key, this.socket);
    this.socket = getWsInstance(wsEndpoint, key, true);

    this.socket.on('open', () => {
      console.log('ws connected');
      this.props.dispatch(setConnected(true));
    });

    this.socket.on('close', (err) => {
      console.log('ws close', err);
      this.props.dispatch(setConnected(false));
    });

    this.socket.on('error', (error) => {
      console.log('ws error', error);
      this.setState({ error: error.message });
    });
    this.socket.on('message', (data) => {
      if (data.error && Object.keys(data.error).length > 0) {
        this.setState({ error: data.error });
        if (data.type === 'InvalidApiKey') {
          // this.setState({ running: false });
        }
        setTimeout(() => {
          this.setState({ error: null });
        }, 3000);
      }
    });

    this.socket.connect();

    this.context.setSocket(this.socket);
  }

  render() {
    const {
      error, breadcrumbs, key,
    } = this.state;
    // console.log(breadcrumbs);
    const { children } = this.props;
    return (
      <div className="app">
        {error ? (
          <Toast message={error} />
        ) : ''}
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Início</Link></li>
            {
              key ? (
                <li className="breadcrumb-item"><Link to={`/bitmex/${key}`}>{key}</Link></li>
              ) : ''
            }
            {
              breadcrumbs ? (
                <li className="breadcrumb-item active" aria-current="page">{breadcrumbs.name}</li>
              ) : ''
            }
          </ol>
        </nav>
        {children}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  keys: state.keys,
});

export default withRouter(connect(mapStateToProps)(BitmexLayout));
