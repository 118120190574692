import React from 'react';
import {
  collection, getDocs, query, where,
} from 'firebase/firestore';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from '../service/withRouter';
import { db } from '../service/firebase';
import { saveKeys } from '../redux/slices/key';
import KeyCard from '../components/KeyCard';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keys: null,
    };
  }

  componentDidMount() {
    const { auth } = this.props;
    if (auth) {
      this.loadKeys();
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.auth) !== JSON.stringify(this.props.auth)) {
      this.loadKeys();
    }
  }

  async loadKeys() {
    const { auth } = this.props;
    if (auth) {
      const usersRef = collection(db, 'keys');
      const q = query(usersRef, where('user', '==', auth.user.id));
      const snapshot = await getDocs(q);

      // console.log('keys', snapshot.docs);
      this.setState({
        keys: snapshot && snapshot.docs,
      });
      const keys = snapshot.docs.map((doc) => {
        const key = doc.data();
        key.id = doc.id;
        if (key.updatedAt) {
          key.updatedAt = key.updatedAt.toDate().getTime();
        }
        return key;
      });
      this.props.dispatch(saveKeys(keys));
    }
  }

  render() {
    const { keys } = this.state;
    if (!keys || !keys.length) {
      return (
        <div className="app">
          <div className="row">
            <h3>
              Você ainda não tem uma chave cadastrada
            </h3>
          </div>
          <div className="row">
            <h4>
              <Link to="/api-key" className="btn btn-link">Cadastre uma para começar</Link>
            </h4>
          </div>
        </div>
      );
    }
    return (
      <div className="app">
        <div className="row">
          <h3>
            Escolha uma chave para começar:
          </h3>
        </div>
        <div className="row row-cols-2 row-cols-sm-2 row-cols-md-4">
          {' '}
          {keys ? keys.map((doc) => (
            <KeyCard key={doc.id} doc={doc} />
          )) : ''}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  keys: state.keys,
});

export default withRouter(connect(mapStateToProps)(Dashboard));
