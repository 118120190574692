import React, { useEffect } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useDispatch } from 'react-redux';
import { db } from './service/firebase';
import { saveUser } from './redux/slices/auth';
import ProtectedRoute from './layout/Protected';

import Login from './pages/login';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import Binance from './pages/binance';
import Bitmex from './pages/bitmex';
import ApiForm from './pages/form';
import BinanceLinear from './pages/binance/linear';
import BitmexLinear from './pages/bitmex/linear';
import PageNotFound from './pages/NotFound';
// import './App.css';
import './styles/bootstrap-cyborg.min.css';

function App() {
  const auth = getAuth();

  const dispatch = useDispatch();
  useEffect(() => {
    onAuthStateChanged(auth, async (fbUser) => {
      if (fbUser) {
        const userRef = doc(db, 'users', fbUser.uid);
        const userDoc = await getDoc(userRef);
        const userData = userDoc.data();
        const user = {
          ...userData, name: fbUser.displayName, id: fbUser.uid, email: fbUser.email,
        };
        dispatch(saveUser(user));
      } else {
        dispatch(saveUser(null));
      }
    });
  }, [auth, dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/api-key"
          element={(
            <ProtectedRoute>
              <ApiForm />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/api-key/:apiKey/"
          element={(
            <ProtectedRoute roles={['admin', 'trader']}>
              <ApiForm />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/binance/linear/:apiKey/"
          element={(
            <ProtectedRoute roles={['admin', 'trader']}>
              <BinanceLinear />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/binance/:apiKey"
          element={(
            <ProtectedRoute roles={['admin', 'trader']}>
              <Binance />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/bitmex/linear/:apiKey/"
          element={(
            <ProtectedRoute roles={['admin', 'trader']}>
              <BitmexLinear />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/bitmex/:apiKey/"
          element={(
            <ProtectedRoute roles={['admin', 'trader']}>
              <Bitmex />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/dashboard"
          element={(
            <ProtectedRoute roles={['admin']}>
              <Dashboard />
            </ProtectedRoute>
          )}
        />
        <Route
          exact
          path="/"
          element={(
            <ProtectedRoute roles={['admin', 'trader']}>
              <Home />
            </ProtectedRoute>
          )}
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
