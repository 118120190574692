/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const binanceWsSlice = createSlice({
  name: 'binanceWs',
  initialState,
  reducers: {
    setError: (state, action) => {
      const error = action.payload;
      state.error = error;
    },
    setConnected: (state, action) => {
      const value = action.payload;
      state.connected = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConnected, setError } = binanceWsSlice.actions;

export default binanceWsSlice.reducer;
