/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {
  Navigate,
  Outlet,
} from 'react-router-dom';

import { connect } from 'react-redux';
import withRouter from '../service/withRouter';
import Menu from '../components/Menu';
import { SocketProvider } from '../context/SocketContext';

function ProtectedRoute({ children, auth, roles }) {
  const user = auth && auth.user;
  // const user = useSelector((state) => state.auth.value);
  if (user) {
    const role = user.role || 'trader';
    // console.log('user', user);
    if (roles && roles.indexOf(role) === -1) {
      return (<Navigate to="/forbidden" replace />);
    }
    return (
      <SocketProvider>
        <div className="container">
          <Menu user={user} />
          {children || <Outlet />}
        </div>
      </SocketProvider>
    );
  }
  if (typeof user === 'undefined') {
    // eslint-disable-next-line react/no-unstable-nested-components, func-names
    return (
      <SocketProvider>
        <div className="jumbotron d-flex align-items-center min-vh-100">
          <div className="container text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        </div>
      </SocketProvider>
    );
  }
  return (<Navigate to="/login" replace />);
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default withRouter(connect(mapStateToProps)(ProtectedRoute));
