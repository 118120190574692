/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const keySlice = createSlice({
  name: 'keys',
  initialState,
  reducers: {
    saveKeys: (state, action) => {
      const keys = action.payload;
      state.keys = keys;
    },
    saveKey: (state, action) => {
      const { key, value } = action.payload;
      state.key[key] = value;
    },
    setActiveKey: (state, action) => {
      const value = action.payload;
      state.activeKey = value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveKeys, saveKey, setActiveKey } = keySlice.actions;

export default keySlice.reducer;
