import ReconnectingWebSocket from 'reconnecting-websocket';
import EventEmitter from 'eventemitter3';
import sleep from '../modules/sleep';

class Ws extends EventEmitter {
  url = null;

  connected = false;

  _reconnect = false;

  _timerId = null;

  constructor(url, api, reconnect = true) {
    super();
    this.url = url;
    this._api = api;
    this._reconnect = reconnect;
  }

  connect() {
    if (this.socket && this.socket.readyState === this.socket.OPEN) {
      // console.log('this.socket was open');
      this.emit('open');
      return;
    }
    this.socket = new ReconnectingWebSocket(this.url, [], {
      connectionTimeout: 1000,
      maxRetries: 10,
    });
    this.socket.addEventListener('open', (evt) => {
      this.emit('open', evt);
    });
    this.socket.addEventListener('message', (message) => {
      try {
        const data = JSON.parse(message.data);
        if (data.type === 'connected') {
          this.connected = true;
          this.emit('connected');
        } else {
          console.log(data.type, data);
          this.emit(data.type, data);
        }
      } catch (err) {
        console.error('message error', err, message);
        this.emit('error', err);
      }
    });
    this.socket.addEventListener('close', (evt) => {
      this.connected = false;
      // console.log('socket close', evt);
      this.emit('close', evt);
    });
    this.socket.addEventListener('error', (err) => {
      console.log('socket error', err);
      this.emit('error', err);
    });
    // console.log('socket connect', this.socket, this.socket.OPEN);
  }

  async send(type, data = {}) {
    if (!this.socket) {
      throw new Error('Socket not connected');
    }
    while (this.connected === false) {
      console.log('sending waiting', this.socket && this.socket.readyState, this.connected);
      await sleep(1000);
    }
    console.log('sending ready', type, this.connected);
    if (typeof data === 'string') {
      return this.socket.send(data);
    }
    // console.log('sending', type);
    return this.socket.send(JSON.stringify({
      type,
      api: this._api,
      ...data,
    }));
  }
}

const instances = {};

function getWsInstance(url, api, reconnect = true) {
  if (!instances[url]) {
    instances[url] = new Ws(url, api, reconnect);
  } else {
    // console.log('reusando', url);
  }
  return instances[url];
}

export { getWsInstance };
export default Ws;
