import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth';
import keyReducer from '../slices/key';
import binanceWsReducer from '../slices/binanceWs';
import bitmexWsReducer from '../slices/bitmexWs';

export default configureStore({
  reducer: {
    auth: authReducer,
    keys: keyReducer,
    binanceWs: binanceWsReducer,
    bitmexWs: bitmexWsReducer,
  },
});
