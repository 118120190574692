import React from 'react';
import { AdvancedChart } from 'react-tradingview-embed';
import { connect } from 'react-redux';
import withRouter from '../../service/withRouter';
import Form from '../../components/bitmex/linearForm';
import Position from '../../components/bitmex/Position';
import BitmexLayout from '../../layout/Bitmex';
import SocketContext from '../../context/SocketContext';

class BitmexLinear extends React.Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);
    this.state = {
      keyData: props.keyData,
      connected: props.connected,
      running: false,
    };
  }

  componentDidMount() {
    const { socket } = this.context;
    if (socket) {
      this.socket = socket;
      this.startSocket(socket);
    }
  }

  startSocket() {
    this.socket.on('linear', (data) => {
      if (data.running) {
        console.log('running');
        this.setState({
          running: true,
        });
      }
    });
    this.socket.send('bitmex/linear');
  }

  componentDidUpdate(prevProps) {
    const { socket } = this.context;
    if (JSON.stringify(prevProps.keys.activeKey) !== JSON.stringify(this.props.keys.activeKey)) {
      this.setState({
        keyData: this.props.keys.activeKey,
      });
    }
    if (!this.socket && socket) {
      this.socket = socket;
      this.startSocket(socket);
    }
  }

  render() {
    const { keyData, connected, running } = this.state;
    return (
      <BitmexLayout breadcrumbs={{
        name: 'Linear',
      }}
      >
        <h3>Bitmex Linear Bot</h3>
        <div className="row">
          <div className="col-4">
            <Form keyData={keyData} connected={connected} running={running} />
          </div>
          <div className="col-8">
            <AdvancedChart
              widgetProps={{
                theme: 'dark',
                symbol: 'BINANCE:BTCUSDT',
                autosize: false,
                height: '450px',
                locale: 'br',
                interval: '15',
                timezone: 'America/Sao_Paulo',
                hide_side_toolbar: true,
                withdateranges: false,
                allow_symbol_change: false,
                style: '1',
              }}
              height="100px"
            />
          </div>
        </div>
        <div className="row">
          <Position />
        </div>
      </BitmexLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  keys: state.keys,
});

export default withRouter(connect(mapStateToProps)(BitmexLinear));
