import React from 'react';
import {
  doc, updateDoc, addDoc, collection, getDoc,
  // collection, getDocs, query, where,
} from 'firebase/firestore';
import { Form as FormFormik, Formik } from 'formik';
import { Form, Row, Col } from 'react-bootstrap';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import withRouter from '../service/withRouter';
import { db } from '../service/firebase';

import SocketContext from '../context/SocketContext';

const ApiSchema = Yup.object().shape({
  type: Yup.string().required('Obrigatório'),
  initialBankroll: Yup.string().required('Obrigatório'),
  name: Yup.string().required('Obrigatório'),
  apiKey: Yup.string().required('Obrigatório'),
  secretKey: Yup.string().required('Obrigatório'),
});

class ApiForm extends React.Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);
    this.state = {
      auth: props.auth,
      key: props.router.params && props.router.params.apiKey,
      result: null,
      message: null,
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  async componentDidMount() {
    const { key } = this.state;
    if (key) {
      this.loadKey();
    }
  }

  async loadKey() {
    const { key } = this.state;
    if (key) {
      const keyRef = doc(db, 'keys', key);

      const snapshot = await getDoc(keyRef);
      if (snapshot.exists()) {
        const keyData = snapshot.data();
        keyData.id = snapshot.id;
        this.context.setKeyData(keyData);
      } else {
        this.setState({ message: 'Chave API inválida' });
      }
    }
  }

  async onSubmit(values, { setSubmitting }) {
    const { auth, key } = this.state;

    // console.log('key', key);
    const isAdmin = auth && auth.user && auth.user.role === 'admin';
    const data = {
      type: values.type,
      name: values.name,
      apiKey: values.apiKey,
      secretKey: values.secretKey,
      initialBankroll: values.initialBankroll,
      user: auth && auth.user && auth.user.id,
    };

    if (key) {
      data.updatedAt = new Date();
    } else {
      data.createdAt = new Date();
    }

    if (key && !isAdmin && data.initialBankroll) {
      delete data.initialBankroll;
    }
    // console.log('data', data);
    if (key) {
      const userRef = doc(db, 'keys', key);
      await updateDoc(userRef, data);
      this.setState({ result: true, message: 'Chave alterada com sucesso. Redicionando para a página inicial...' });
      setTimeout(() => {
        // console.log(this.props.router);
        this.props.router.navigate('/');
      }, 5000);
    } else {
      const userRef = collection(db, 'keys');
      await addDoc(userRef, data).catch(console.error);
      this.setState({ result: true, message: 'Chave adicionada com sucesso. Redicionando para a página inicial...' });
      setTimeout(() => {
        // console.log(this.props.router);
        this.props.router.navigate('/');
      }, 5000);
    }
    setSubmitting(false);
  }

  render() {
    const {
      key, result, message, auth,
    } = this.state;
    const { keyData } = this.context;
    const isAdmin = auth && auth.user && auth.user.role === 'admin';

    console.log({ isAdmin });

    if (key && !keyData) {
      return (
        <div className="jumbotron d-flex align-items-center min-vh-100">
          <div className="container text-center">
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Carregando...</span>
            </div>
          </div>
        </div>
      );
    }
    if (result) {
      return (
        <div className="alert alert-success" role="alert">
          {message}
        </div>
      );
    }
    return (
      <div>
        <h3>
          {key ? 'Atualizar' : 'Nova'}
          {' '}
          chave API
        </h3>
        <Formik
          initialValues={{
            type: key ? keyData.type : 'bitmex',
            name: key ? keyData.type : '',
            initialBankroll: key ? keyData.initialBankroll : '',
            apiKey: key ? keyData.apiKey : '',
            secretKey: key ? keyData.secretKey : '',
          }}
          validationSchema={ApiSchema}
          onSubmit={this.onSubmit}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            // isValid,
            errors,
          }) => (
            <FormFormik className="form-container" onSubmit={handleSubmit}>
              <Form.Group controlId="type" as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Corretora
                </Form.Label>
                <Col sm={10}>
                  <Form.Select
                    type="text"
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.type && !errors.type}
                  >
                    <option value="">Escolha uma corretora</option>
                    <option value="binance">Binance</option>
                    <option value="bitmex">BitMEX</option>
                    {/* <option value="bibyt">BiByt</option> */}
                  </Form.Select>
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.type}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="name" as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Nome da chave
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="name"
                    placeholder="Um nome para identificar a chave"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.name && !errors.name}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="initialBankroll" as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Banca Inicial
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="number"
                    name="initialBankroll"
                    disabled={key && !isAdmin}
                    value={values.initialBankroll}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.initialBankroll && !errors.initialBankroll}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.initialBankroll}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="apiKey" as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Chave da API
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="text"
                    name="apiKey"
                    value={values.apiKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.apiKey && !errors.apiKey}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.apiKey}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="secretKey" as={Row} className="mb-3">
                <Form.Label column sm={2}>
                  Segredo da API
                </Form.Label>
                <Col sm={10}>
                  <Form.Control
                    type="password"
                    name="secretKey"
                    value={values.secretKey}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.secretKey && !errors.secretKey}
                  />
                </Col>
                <Form.Control.Feedback type="invalid">
                  {errors.secretKey}
                </Form.Control.Feedback>
              </Form.Group>
              <button type="submit" className="btn btn-primary">
                {key ? 'Atualizar' : 'Adicionar'}
              </button>
            </FormFormik>
          )}
        </Formik>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default withRouter(connect(mapStateToProps)(ApiForm));
