import React from 'react';
import { connect } from 'react-redux';

import withRouter from '../../service/withRouter';

import SocketContext from '../../context/SocketContext';
import axios from '../../service/api';

class Position extends React.Component {
  static contextType = SocketContext;

  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      position: null,
      order: null,
      key: props.router.params.apiKey,
      socket: null,
    };
    this.cancelOrder = this.cancelOrder.bind(this);
  }

  componentDidMount() {
    const { socket } = this.context;
    if (socket) {
      this.setState({ socket }, () => {
        this.startSocket();
      });
    }
  }

  componentDidUpdate() {
    const { socket } = this.context;
    if (!this.state.socket && socket) {
      this.setState({ socket }, () => {
        this.startSocket();
      });
    }
  }

  async startSocket() {
    const { socket } = this.state;
    // socket.send('bitmex/balance');
    socket.on('position', (data) => {
      // console.log('position', data);
      if (data.position) {
        let { position } = data;
        if (data.position.length && data.position[0].length) {
          // eslint-disable-next-line prefer-destructuring
          position = data.position[0][0];
          // console.log('position2', position);
          this.setState({ position });
        }
      }
      // if (data.balances) {
      //   this.setState({ balances: data.balance });
      // }
    });
    socket.on('order', (data) => {
      if (data.order) {
        let { order } = data;
        if (data.order.length && data.order[0].length) {
          // eslint-disable-next-line prefer-destructuring
          order = data.order[0];
          // console.log('order', order);
          this.setState({ order });
        }
      }
      // if (data.balances) {
      //   this.setState({ balances: data.balance });
      // }
    });
    socket.send('bitmex/position');
    socket.send('bitmex/order');
  }

  // eslint-disable-next-line class-methods-use-this
  async cancelOrder(orderID) {
    const res = await axios.post('/bitmex/order/cancel', {
      key: this.state.key,
      orderID,
    }).catch((err) => {
      console.error(err);
      return false;
    });
    console.log(res);
  }

  render() {
    const { position, order } = this.state;
    return (
      <div className="row">
        {position && position.isOpen ? (
          <div className="col-12">
            <h4>Posição</h4>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Tipo</th>
                  <th scope="col">Contratos</th>
                  <th scope="col">Preço de entrada</th>
                  <th scope="col">Preço atual</th>
                  <th scope="col">Alavancagem</th>
                  <th scope="col">Liquidation</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span className={`float-start text-${position.currentQty > 0 ? 'success' : 'danger'}`}>
                      {position.currentQty > 0 ? 'Long' : 'Short'}
                    </span>
                  </th>
                  <td>{position.currentQty}</td>
                  <td>{`$${position.avgEntryPrice}`}</td>
                  <td>{`$${position.lastPrice}`}</td>
                  <td>
                    {`${position.leverage}x`}
                  </td>
                  <td>
                    <span className="float-start text-danger">
                      {`$${position.liquidationPrice}`}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : ''}
        {order ? (
          <div className="col-12">
            <h4>Ordens</h4>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Lado</th>
                  <th scope="col">Tipo</th>
                  <th scope="col">Contratos</th>
                  <th scope="col">Preço</th>
                  <th scope="col">Data</th>
                  <th scope="col">Ações</th>
                </tr>
              </thead>
              <tbody>
                {order.map((item) => {
                  const ord = item;
                  const date = new Date(ord.transactTime);
                  if (ord.ordStatus === 'Canceled') {
                    return '';
                  }
                  // console.log({ item });
                  // if (!item || !item.length) {
                  //   return '';
                  // }
                  return (
                    <tr key={ord.orderID}>
                      <th scope="row">
                        <span className={`float-start text-${ord.side === 'Buy' ? 'success' : 'danger'}`}>
                          {ord.side === 'Buy' ? 'Compra' : 'Venda'}
                        </span>
                      </th>
                      <td>{ord.ordType}</td>
                      <td>{ord.orderQty}</td>
                      <td>{`$${ord.price}`}</td>
                      <td>
                        {date.toLocaleString()}
                        {' '}
                      </td>
                      <th scope="row">
                        <button
                          type="button"
                          className="btn btn-danger"
                          onClick={() => {
                            this.cancelOrder(ord.orderID);
                          }}
                        >
                          Cancelar
                        </button>
                      </th>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        ) : ''}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({ auth: state.auth });

export default withRouter(connect(mapStateToProps)(Position));
