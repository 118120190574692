/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  getDoc, doc,
} from 'firebase/firestore';
import { generatePath } from 'react-router';
import { connect } from 'react-redux';
import Card from 'react-bootstrap/Card';
import { Pencil } from 'react-bootstrap-icons';

import withRouter from '../service/withRouter';
import { db } from '../service/firebase';
import axios from '../service/api';

class KeyCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      key: props.doc.data(),
      id: props.doc.id,
      position: null,
      user: null,
    };
  }

  componentDidMount() {
    const { key } = this.state;
    if (key) {
      this.loadPosition();
      this.loadUser();

      this._interval = setInterval(() => {
        this.loadPosition();
      }, 15000);
    }
  }

  componentWillUnmount() {
    if (this._interval) {
      clearInterval(this._interval);
    }
  }

  componentDidUpdate(prevProps) {
    // console.log(this.props);
    if (this.props.id && prevProps.key !== this.props.id) {
      this.setState({
        id: this.props.id,
      }, () => {
        this.loadPosition();
        this.loadUser();
      });
    }
  }

  async loadUser() {
    const { key } = this.state;
    const usersRef = doc(db, 'users', key.user);
    const user = await getDoc(usersRef);
    const userData = user.data();
    this.setState({ user: userData });
  }

  async loadPosition() {
    const { key, id } = this.state;
    const result = await axios.get(`/${key.type}/position?key=${id}`).catch(console.error);
    if (result && result.data) {
      this.setState({ position: result.data });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  calcPc(n1, n2) {
    // eslint-disable-next-line no-mixed-operators
    const pc = (`${((n2 - n1) / n1 * 100).toLocaleString('fullwide', { maximumFractionDigits: 4 }).substring(0, 4)}`);
    return pc;
  }

  render() {
    const {
      key, position, user, id,
    } = this.state;
    let isShort = false;
    let isLong = false;
    let openPosition;
    let balance = null;
    let coin = 'BTC';
    let { initialBankroll } = key;
    if (position) {
      openPosition = position.position && position.position;
      isLong = !!((openPosition && openPosition.isOpen && openPosition.currentQty > 0));
      isShort = !!((openPosition && openPosition.isOpen && openPosition.currentQty < 0));
    }
    if (position && (position.error || (position.position && position.position.statusCode === 401))) {
      return (
        <>
        </>
      );
    } if (position) {
      openPosition = position.position && position.position;
      if (key.type === 'bitmex') {
        isLong = !!((openPosition && openPosition.isOpen && openPosition.currentQty > 0));
        isShort = !!((openPosition && openPosition.isOpen && openPosition.currentQty < 0));
        balance = position && position.balance && position.balance.walletBalance ? position.balance.walletBalance / 100000000 : null;
      } else if (key.type === 'binance') {
        isLong = !!((openPosition && position.updateTime !== 0 && openPosition.positionSide === 'LONG'));
        isShort = !!((openPosition && position.updateTime !== 0 && openPosition.positionSide === 'SHORT'));

        if (position && position.balance && position.balance.assets && position.balance.assets.length) {
          if (parseFloat(position.balance.assets[0].free) <= 0) {
            balance = parseFloat(position.balance.assets[1].free);
            coin = 'USDT';
            initialBankroll = 200;
          } else {
            balance = parseFloat(position.balance.assets[0].free);
          }
        }
      }
    }

    const link = generatePath(`/${key.type}/:apiKey`, {
      apiKey: id,
    });

    const linkEdit = generatePath('/api-key/:apiKey', {
      apiKey: id,
    });

    const profit = balance ? this.calcPc(initialBankroll, balance) : null;
    // console.log(profit, parseFloat(profit) >= 0);

    return (
      <div className="col d-flex align-items-stretch mb-20" key={`linear-${key.apiKey}`}>
        <Card style={{ width: '25rem', marginBottom: '2rem' }}>
          <Card.Body>
            <Card.Title>
              {user && user.name}
              {' - '}
              {key.name}
            </Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              {key.type}
              {' '}
              {`${key.apiKey.substr(0, 4)}*****${key.apiKey.substr(-4)}`}

              <Card.Link style={{ float: 'right' }} href={linkEdit}>
                <Pencil />
              </Card.Link>
            </Card.Subtitle>
            {position && position.error ? (
              <Card.Text>
                Erro:
                {' '}
                {position.message}
              </Card.Text>
            ) : (
              <>
                <Card.Text>
                  <span className="text-muted ">Inicial:</span>
                  <span className="float-end">
                    {`${initialBankroll.toFixed(4).replace(/\.0+$/, '')} ${coin}`}
                  </span>
                </Card.Text>
                <Card.Text>
                  <span className="text-muted ">Atual:</span>
                  <span className="float-end">
                    {balance ? `${balance.toFixed(4).replace(/\.0+$/, '')} ${coin}` : '...'}
                  </span>
                </Card.Text>
                <Card.Text>
                  <span className="text-muted ">Ganhos:</span>
                  <span className={`float-end ${!profit ? '' : (parseFloat(profit) >= 0 ? 'text-success' : 'text-danger')}`}>
                    {`${profit}%` || '...'}
                  </span>
                </Card.Text>
                {isShort || isLong ? (
                  <>
                    <hr />
                    <Card.Text>
                      <span className="text-muted ">Posição:</span>
                      <span className={`float-end text-${isLong ? 'success' : 'danger'}`}>
                        {!isLong ? 'Short' : 'Long'}
                      </span>
                    </Card.Text>
                    <Card.Text>
                      <span className="text-muted">Contratos:</span>
                      <span className="float-end">
                        {openPosition.currentQty >= 0 ? openPosition.currentQty : (openPosition.currentQty * -1)}
                      </span>
                    </Card.Text>
                    <Card.Text>
                      <span className="text-muted">Alavancagem:</span>
                      <span className="float-end">
                        {openPosition.leverage}
                        x
                      </span>
                    </Card.Text>
                    <Card.Text>
                      <span className="text-muted">Preço entrada:</span>
                      {' '}
                      <span className="float-end">
                        $
                        {`${parseInt(openPosition.avgEntryPrice, 10)}`}
                      </span>
                    </Card.Text>
                    <Card.Text>
                      <span className="text-muted">Liquidation:</span>
                      {' '}
                      <span className="float-end text-danger">
                        $
                        {`${parseInt(openPosition.bankruptPrice, 10)}`}
                      </span>
                    </Card.Text>
                  </>
                ) : ''}
              </>
            )}

            <Card.Link href={link} className="d-flex justify-content-center">
              Usar
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ auth: state.auth });

export default withRouter(connect(mapStateToProps)(KeyCard));
