/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-shadow */
// import { useState } from 'react';
// eslint-disable-next-line no-undef
import Toast from 'react-bootstrap/Toast';
import ToastContainer from 'react-bootstrap/ToastContainer';

function ToastComponent({
  message, title = '', position = 'top-end', type = 'danger',
}) {
  return (
    <div>
      <ToastContainer className="p-3" position={position}>
        <Toast className={`align-items-center text-bg-${type} border-0`} role="alert">
          <Toast.Header closeButton={false}>
            {title || ''}
          </Toast.Header>
          <Toast.Body>{message || ''}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
}
export default ToastComponent;
