import { useDispatch, connect } from 'react-redux';
import { saveUser } from '../redux/slices/auth';
import { auth as Auth } from '../service/firebase';

function Menu({ auth }) {
  const dispatch = useDispatch();

  const handleLogout = () => {
    Auth.signOut(auth)
      .then(() => {
        dispatch(saveUser(undefined));
        console.log('user signed out');
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const admin = auth && auth.user && auth.user.role === 'admin'; // auth  && auth.user
  // console.log('admin', admin);

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">GAS HFT</a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="/">Início</a>
            </li>
            {admin ? (

              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/dashboard">Dashboard</a>
              </li>
            ) : ''}
            <li className="nav-item dropdown">
              <a className="nav-link" href="/api-key" role="button" aria-expanded="false">
                Cadastrar nova chave
              </a>
            </li>
          </ul>
          <div className="d-flex">
            {/* {<span className="navbar-text">
              Olá,
              {user && user.name}
            </span>} */}
            <li className="nav-item">
              <button type="button" className="btn btn-outline-danger" onClick={handleLogout}>Sair</button>
            </li>
          </div>
        </div>
      </div>
    </nav>
  );
}
const mapStateToProps = (state) => ({ auth: state.auth });
export default connect(mapStateToProps)(Menu);
