import React from 'react';
import '../styles/404.css';

function PageNotFound() {
  document.body.className = 'notFound';
  // console.log('404');
  return (
    <div className="notFound">
      <div className="dialog notFound">
        <h1 className="notFound">Oops!</h1>
        <p className="notFound">Não achamos a página que você está procurando.</p>
      </div>
    </div>
  );
}

export default PageNotFound;
