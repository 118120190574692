import React from 'react';
import {
  getDoc, doc, setDoc,
} from 'firebase/firestore';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import axios from '../../service/api';
import withRouter from '../../service/withRouter';
import { db } from '../../service/firebase';

import SocketContext from '../../context/SocketContext';

class Dashboard extends React.Component {
  static contextType = SocketContext;

  constructor(props) {
    super(props);
    this.state = {
      running: false,
      key: props.router.params.apiKey,
      keyData: props.keys.activeKey,
      configData: {},
      connected: props.binanceWs.connected,
    };
    this.start = this.start.bind(this);
    this.stop = this.stop.bind(this);
  }

  async componentDidMount() {
    const { key } = this.state;
    if (key) {
      this.loadConfig();
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.keys.activeKey) !== JSON.stringify(this.props.keys.activeKey)) {
      this.setState({
        keyData: this.props.keys.activeKey,
      });
    }
    if (JSON.stringify(prevProps.binanceWs.connected) !== JSON.stringify(this.props.binanceWs.connected)) {
      this.setState({
        connected: this.props.binanceWs.connected,
      });
    }
  }

  async loadConfig() {
    const { key } = this.state;
    if (key) {
      const configRef = doc(db, 'configs', key);

      const snapshot = await getDoc(configRef);

      if (snapshot.exists()) {
        const data = snapshot.data();
        const configData = data.linear || {};
        this.setState({
          configData,
        });
      }
    }
  }

  async start(values) {
    const { key } = this.state;

    console.log(key, values);

    const res = await axios.post('/binance/linear/start', {
      key,
      config: values,
    });
    console.log(res);
    const configRef = doc(db, 'configs', key);
    await setDoc(configRef, { linear: values }, { merge: true });

    this.setState({ configData: values, running: true });
  }

  async stop() {
    const { key } = this.state;
    const res = await axios.post('/binance/linear/stop', {
      key,
    });
    console.log(res);
    this.setState({ running: false });
  }

  render() {
    const { running, connected } = this.state;
    const { keyData, configData } = this.state;
    if (!keyData) {
      return (
        <>
          Carregando
        </>
      );
    }
    return (
      <Formik
        initialValues={{
          VALUE: configData.VALUE || 0.001,
          REFRESH_TIME: configData.REFRESH_TIME || 15,
          RANGE: configData.RANGE || 50,
          RANGE_ON_POSITION: configData.RANGE_ON_POSITION || 100,
          NUMBER_OF_ORDERS: configData.NUMBER_OF_ORDERS || 3,
        }}
        onSubmit={running ? this.stop : this.start}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="VALUE" className="form-label">Valor</label>
              <input
                disabled={running || !connected}
                id="VALUE"
                type="text"
                name="VALUE"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.VALUE}
              />
              {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
              {errors.VALUE && touched.VALUE && errors.VALUE}
            </div>
            <div className="mb-3">
              <label htmlFor="REFRESH_TIME" className="form-label">Tempo de atualização</label>
              <input
                disabled={running || !connected}
                id="REFRESH_TIME"
                type="number"
                name="REFRESH_TIME"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.REFRESH_TIME}
              />
              {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
              {errors.REFRESH_TIME && touched.REFRESH_TIME && errors.REFRESH_TIME}
            </div>
            <div className="mb-3">
              <label htmlFor="RANGE" className="form-label">Range</label>
              <input
                disabled={running || !connected}
                id="RANGE"
                type="number"
                name="RANGE"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.RANGE}
              />
              {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
              {errors.RANGE && touched.RANGE && errors.RANGE}
            </div>
            <div className="mb-3">
              <label htmlFor="RANGE_ON_POSITION" className="form-label">Range quando em posição</label>
              <input
                disabled={running || !connected}
                id="RANGE_ON_POSITION"
                type="number"
                name="RANGE_ON_POSITION"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.RANGE_ON_POSITION}
              />
              {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
              {errors.RANGE_ON_POSITION && touched.RANGE_ON_POSITION && errors.RANGE_ON_POSITION}
            </div>
            <div className="mb-3">
              <label htmlFor="NUMBER_OF_ORDERS" className="form-label">Num. de ordens</label>
              <input
                disabled={running || !connected}
                id="NUMBER_OF_ORDERS"
                type="number"
                name="NUMBER_OF_ORDERS"
                className="form-control"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.NUMBER_OF_ORDERS}
              />
              {/* <div id="emailHelp" className="form-text">We'll never share your email with anyone else.</div> */}
              {errors.NUMBER_OF_ORDERS && touched.NUMBER_OF_ORDERS && errors.NUMBER_OF_ORDERS}
            </div>

            {(running) ? (
              <button type="button" onClick={this.stop} disabled={isSubmitting || !connected} className="btn btn-lg btn-danger">
                Parar
              </button>
            ) : (
              <button type="submit" disabled={running || !connected} className="btn btn-lg btn-success">
                Iniciar
              </button>
            )}
          </form>
        )}
      </Formik>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  binanceWs: state.binanceWs,
  keys: state.keys,
});

export default withRouter(connect(mapStateToProps)(Dashboard));
