import React from 'react';
import { AdvancedChart } from 'react-tradingview-embed';
import { connect } from 'react-redux';
import withRouter from '../../service/withRouter';
import Form from './linearForm';
import Position from '../../components/binance/Position';
import BinanceLayout from '../../layout/Binance';

class Binance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      keyData: props.keyData,
      connected: props.connected,
      socket: props.socket,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.keys.activeKey) !== JSON.stringify(this.props.keys.activeKey)) {
      this.setState({
        keyData: this.props.keys.activeKey,
      });
    }
    if (!prevProps.socket && this.props.socket) {
      this.setState({
        socket: this.props.socket,
      });
    }
  }

  render() {
    const { keyData, socket, connected } = this.state;
    return (
      <BinanceLayout breadcrumbs={{
        name: 'Linear',
      }}
      >
        <h2>Binance Bot Linear</h2>
        <div className="row">
          <Position />
        </div>
        <br />
        <div className="row">
          <div className="col-4">
            <Form keyData={keyData} connected={connected} socket={socket} />
          </div>
          <div className="col-8">
            <AdvancedChart
              widgetProps={{
                theme: 'dark',
                symbol: 'BINANCE:BTCUSDT',
                autosize: false,
                height: '450px',
                locale: 'br',
                interval: '15',
                timezone: 'America/Sao_Paulo',
                hide_side_toolbar: true,
                withdateranges: false,
                allow_symbol_change: false,
                style: '1',
              }}
              height="100px"
            />
          </div>
        </div>
      </BinanceLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  keys: state.keys,
});

export default withRouter(connect(mapStateToProps)(Binance));
