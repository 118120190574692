import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import withRouter from '../../service/withRouter';
import BitmexLayout from '../../layout/Bitmex';
import Position from '../../components/bitmex/Position';

class BitmexHome extends React.Component {
  constructor(props) {
    super(props);
    let { keyData } = props;
    // console.log('props', props);
    if (!keyData && props.keys.activeKey) {
      keyData = props.keys.activeKey;
    }
    this.state = {
      keyData,
      key: props.router.params.apiKey,
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.keys.activeKey) !== JSON.stringify(this.props.keys.activeKey)) {
      this.setState({
        keyData: this.props.keys.activeKey,
      });
    }
    if (JSON.stringify(prevProps.key) !== JSON.stringify(this.props.key)) {
      this.setState({
        key: this.props.key,
      });
    }
  }

  render() {
    const { keyData, key } = this.state;
    if (!keyData) {
      return (
        <BitmexLayout breadcrumbs={{
          name: 'Linear',
        }}
        >
          Carregando...
        </BitmexLayout>
      );
    }
    return (
      <BitmexLayout>
        <br />
        <h3>
          {keyData.name}
          :
          <small className="text-muted">
            {` ${keyData.apiKey.substr(0, 4)}*****${keyData.apiKey.substr(-4)}`}
          </small>
        </h3>
        <h5>
          Escolha um bot:
        </h5>
        <ul className="list-group">
          <li className="list-group-item" key={`linear-${keyData.apiKey}`}>
            <Link
              type="button"
              className="btn btn-link"
              to={{
                pathname: `/bitmex/linear/${key}`,
              }}
            >
              Linear
            </Link>
          </li>
          <li className="list-group-item" key={`bollinger-band-${keyData.apiKey}`}>
            {/* <Link
              type="button"
              className="btn btn-link"
              to={{
                pathname: `/bitmex/bollinger-band/${key}`,
              }}
            >
              Bollinger Band
            </Link> */}
          </li>
        </ul>
        <div className="row">
          <Position />
        </div>
      </BitmexLayout>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  keys: state.keys,
});

export default withRouter(connect(mapStateToProps)(BitmexHome));
