import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

// TODO: Replace the following with your app's Firebase project configuration
import { firebaseConfig } from '../config/index';

const app = firebase.initializeApp(firebaseConfig);
// const app = ≥÷initializeApp(firebaseConfig);
const db = firebase.firestore(app);
const auth = firebase.auth(app);
auth.languageCode = 'pt';
auth.useDeviceLanguage();

export {
  db, auth, app, firebase,
};
