const env = process.env.NODE_ENV;
export const wsEndpoint = env === 'production' ? 'wss://openwings.com.br/ws' : 'ws://localhost:3001/ws';
export const apiEndpoint = env === 'production' ? 'https://openwings.com.br/api' : 'http://localhost:3001';
export const firebaseConfig = {
  apiKey: 'AIzaSyC0TPMfRSGKA-Gi70Zf2wTCyXfJ-rT2mgI',
  authDomain: 'bots-1259b.firebaseapp.com',
  projectId: 'bots-1259b',
  storageBucket: 'bots-1259b.appspot.com',
  messagingSenderId: '946450464334',
  appId: '1:946450464334:web:966089d522c23bacd80d1f',
};

export default {
  wsEndpoint,
  apiEndpoint,
  firebaseConfig,
};
